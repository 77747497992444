<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Create New Waste Stream</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link to="/waste-streams" v-slot="{ href, navigate }">
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="View All Streams"
                  @click="navigate"
                >
                  <i class="fas fa-list pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <form
                @submit.prevent="submit"
                class="form col-md-8 mx-auto mt-5"
                novalidate="novalidate"
                autocomplete="false"
              >
                <div class="pb-5">
                  <div class="form-group">
                    <label>Waste Stream</label>
                    <input
                      class="form-control form-control-solid form-control-lg"
                      type="text"
                      placeholder="Waste Stream"
                      autocomplete="off"
                      v-model.trim="$v.name.$model"
                    />
                    <template v-if="$v.name.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.name.required"
                      >
                        Waste stream is required.
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >Please enter your waste stream.</span
                    >
                  </div>
                  <div class="form-group">
                    <label>Waste Stream Unit</label>
                    <multiselect
                      v-model.trim="$v.unit.$model"
                      :options="units"
                      track-by="name"
                      label="name"
                      placeholder="Select Waste Stream Units"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                    <template v-if="$v.unit.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.unit.required"
                      >
                        Waste stream unit is required.
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >Please select your waste stream unit.</span
                    >
                  </div>
                  <!-- <div class="form-group">
                    <label>Emission Factor</label>
                    <input
                      class="form-control form-control-solid form-control-lg"
                      type="number"
                      min="0"
                      step=".01"
                      placeholder="Waste Stream Emission Factor"
                      autocomplete="off"
                      v-model.trim="emission"
                    />
                    <span class="form-text text-muted"
                      >Please enter your waste stream emission factor.</span
                    >
                  </div> -->
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Description"
                      value=""
                      rows="5"
                      v-model.trim="description"
                    />
                    <span class="form-text text-muted"
                      >Please describe the waste stream.</span
                    >
                  </div>
                </div>

                <div class="d-flex justify-content-end border-top pt-10 pb-10">
                  <div>
                    <router-link to="/waste-streams" v-slot="{ navigate }">
                      <button
                        type="button"
                        class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                        @click="navigate"
                      >
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                    >
                      <b-spinner
                        v-if="loading == true"
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-if="loading == false">Create Waste Stream</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import GeneralService from "@/core/services/general.service.js";
import WasteStreamService from "@/core/services/waste-stream.service.js";
import PilotCard from "@/view/content/Card.vue";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      name: "",
      description: "",
      unit: "",
      emission: 0.0,
      show: true,
      units: [],
      loading: false
    };
  },
  validations: {
    name: { required },
    unit: { required }
  },
  components: {
    PilotCard,
    Multiselect
  },
  methods: {
    getUnits() {
      GeneralService.units()
        .then(response => {
          this.units = response.data;
        })
        .catch(error => {
          this.makeToast(
            "danger",
            "Oops",
            "An unexpected exception has occured. Could not populate units at the moment, please give it a minute and refresh page"
          );
          console.log(error);
        });
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        const payload = {
          name: this.name,
          unit_id: this.unit.id,
          description: this.description,
          emission_factor: this.emission
        };

        WasteStreamService.create(payload)
          .then(response => {
            Swal.fire({
              title: "Waste Stream Saved",
              text: response.data.message,
              icon: "success",
              heightAuto: false
            });

            this.name = "";
            this.unit = null;
            this.description = "";
            this.emission = "";
            this.loading = false;

            this.$nextTick(() => {
              this.$v.$reset();
            });
          })
          .catch(error => {
            Swal.fire({
              title: "Waste Stream Error",
              text: error.data.message,
              icon: "error",
              heightAuto: false
            });
            this.loading = false;
          });
      }
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Waste Streams", route: "alert" },
      { title: "Create Waste Stream" }
    ]);

    this.getUnits();
  }
};
</script>
